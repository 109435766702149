<template>
  <div class="misc-wrapper">

      <!-- Brand logo-->
      <b-link class="brand-logo" >
          <span >
            <b-img
              :src="appLogoImageText"
              alt="logo"
              style="max-width: 130px"
            />
          </span>
      </b-link>
      <!-- /Brand logo-->

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 class="mb-1">
          Authenticate Required
        </h2>
        <p class="mb-2">
         Please, sign in again
        </p>
        <b-button
          variant="primary"
          class="mb-1 btn-sm-block"
          :to="{ name: 'login', params: {prefix: prefix} }"
        >Go to login</b-button>
        <b-img
          fluid
          :src="imgUrl"
          alt="Not authorized page"
        />
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import { BLink, BImg, BButton } from 'bootstrap-vue'
import { initialAbility } from '@/libs/acl/config'
import { $themeConfig } from '@themeConfig'
import useJwt from '@/auth/jwt/useJwt'
import store from '@/store/index'
import { mapMutations } from "vuex";
export default {
  components: {
    BLink,
    BImg,
    BButton,
  },
  data() {
    return {
      downImg: require('@/assets/images/pages/not-authorized.svg'),
      prefix: localStorage.getItem('prefix') || this.$router.currentRoute.params.prefix
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require('@/assets/images/pages/not-authorized-dark.svg')
        return this.downImg
      }
      return this.downImg
    },
    appLogoImageText(){
      const { appLogoImageText } = $themeConfig.app
      return appLogoImageText
    }
  },
  created(){
    // Remove userData from localStorage
    localStorage.removeItem('userData')
    localStorage.removeItem('sites')

    // Remove accessToken from localStorage
    localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)

    // Reset ability
    this.$ability.update(initialAbility)
  },
  mounted() {
    this.UPDATE_FLAG_SHOW_OVERLAY(false);
  },
  methods: {
    ...mapMutations('app', [
      'UPDATE_FLAG_SHOW_OVERLAY'
    ]),
  }
}
</script>

<style lang="scss">
@import '~@core/scss/vue/pages/page-misc.scss';
</style>
